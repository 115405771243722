<template>
  <div class="container">
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="160px" style="width:600px" class="demo-ruleForm">
      <el-form-item label="下发类型" prop="is_email">
        <el-radio-group v-model="form.is_email">
          <el-radio :label="1">邮件</el-radio>
          <el-radio :label="2">消息</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="消息类型" prop="msg_type">
        <el-select v-model="form.msg_type">
            <el-option label="系统消息" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="终端appid" prop="schema">
        <el-select v-model="form.schema">
          <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="uid" prop="uid">
        <el-select multiple v-model="form.uid">
          <el-option
              v-for="item in userlist"
              :key="item.uid"
              :label="item.nickname"
              :value="item.uid">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.is_email==1" label="填写类型">
        <el-radio-group v-model="fillType">
          <el-radio :label="1">手动</el-radio>
          <el-radio :label="2">选择</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 邮件 -->
      <el-form-item v-if="form.is_email==1&&fillType==2" label="发件人" prop="">
        <el-select v-model="form.push_email_conf_code.code">
          <el-option
              v-for="item in senderlist"
              :key="item.code"
              :label="item.from_name"
              :value="item.code">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.is_email==1&&fillType==1" label="下发邮箱域名" prop="push_email_conf.host">
        <el-input v-model="form.push_email_conf.host"></el-input>
      </el-form-item>
      <el-form-item v-if="form.is_email==1&&fillType==1" label="下发邮箱的端口号" prop="push_email_conf.port">
        <el-input v-model="form.push_email_conf.port"></el-input>
      </el-form-item>
      <el-form-item v-if="form.is_email==1&&fillType==1" label="邮箱账号" prop="push_email_conf.username">
        <el-input v-model="form.push_email_conf.username"></el-input>
      </el-form-item>
      <el-form-item v-if="form.is_email==1&&fillType==1" label="邮箱密码" prop="push_email_conf.password">
        <el-input v-model="form.push_email_conf.password"></el-input>
      </el-form-item>
      <el-form-item v-if="form.is_email==1&&fillType==1" label="下发人显示名称" prop="push_email_conf.from_name">
        <el-input v-model="form.push_email_conf.from_name"></el-input>
      </el-form-item>
      <el-form-item v-if="form.is_email==1&&fillType==1" label="是否是ssl" prop="push_email_conf.ssl">
        <el-radio-group v-model="form.push_email_conf.ssl">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 消息 -->
      <div v-for="(item,key) in logsitem" :key="key">
        <el-form-item label="语言类型">
          <el-select v-model="item.language_code">
            <el-option
                v-for="ele in langueList"
                :key="ele.code"
                :label="ele.name"
                :value="ele.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="item.title"></el-input>
        </el-form-item>
        <el-form-item v-if="form.is_email==2" label="消息概要">
          <el-input v-model="item.abstract"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" rows="5" v-model="item.content"></el-input>
          <el-button style="position: absolute;margin-left: 10px" size="mini" type="warning" @click.prevent="removeMessage(item)">删除</el-button>
        </el-form-item>
        <!-- <el-form-item v-if="form.is_email==2" label="预览图片">
            <el-upload
            class="avatar-uploader"
            :action="s3url"
            :show-file-list="false"
            :http-request="fileUpload"
            @change="handelFileChange"
            :auto-upload="false"
            >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </el-form-item>
        <el-form-item v-if="form.is_email==2" label="消息推送图片">
            <el-input v-model="item.push_img"></el-input>
        </el-form-item> -->
      </div>
      <el-form-item>
        <el-button size="mini" type="info" @click="addMessage">新增消息内容</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="Validate(submitForm)">确定</el-button>
      </el-form-item>
    </el-form>
      

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      fillType: 1,
      form: {
        is_email: 2,
        msg_type: '',
        schema: '',
        uid: [],
        push_email_conf: {
          host: '',
          port: '',
          username: '',
          password: '',
          from_name: '',
          ssl: 2
        },
        push_email_conf_code: {
          code: ''
        }
      },
      rules: {
        is_email: [{ required: true, message: '请输入下发类型', trigger: 'blur' }],
        msg_type: [{ required: true, message: '请输入消息类型', trigger: 'blur' }],
        schema: [{ required: true, message: '请输入终端appid', trigger: 'blur' }],
        uid: [{ required: true, message: '请输入uid', trigger: 'blur' }],
        'push_email_conf.host': [{ required: true, message: '请输入下发邮箱域名', trigger: 'blur' }],
        'push_email_conf.port': [{ required: true, message: '请输入下发邮箱的端口号', trigger: 'blur' }],
        'push_email_conf.username': [{ required: true, message: '请输入邮箱账号', trigger: 'blur' }],
        'push_email_conf.password': [{ required: true, message: '请输入邮箱密码', trigger: 'blur' }],
        'push_email_conf.from_name': [{ required: true, message: '请输入下发人显示名称', trigger: 'blur' }],
        'push_email_conf.ssl': [{ required: true, message: '请选择是否是ssl', trigger: 'blur' }],
      },
      applist: [],
      logsitem: [],
      userlist: [],
      langueList: [],
      senderlist: [],
      // 图片
      s3url: '',
      imageUrl: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    // 文件上传
    fileUpload (file,type) {

    },
    handelFileChange (event) {
      this.upfile = event.raw
    },
    addMessage() {
      this.logsitem.push({
        title: '',
        abstract: '',
        content: '',
        preview_img: '',
        push_img: '',
        language_code: ''
      });
    },
    removeMessage (item) {
      var index = this.logsitem.indexOf(item)
      if (index !== -1) {
        this.logsitem.splice(index, 1)
      }
    },
    GetSenderList () {
        this.api.SenderList({
          page: 1,
          limit: 100
        }).then(res => {
          if (res.data.code == 200) {
            this.senderlist = res.data.data.data
            return
          }
          this.$message.error('语言发送人列表失败' + res.data.msg)
        })
    },
    GetLanguage () {
      this.api.LanguageList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.langueList = res.data.data.data
          this.GetSenderList()
          return
        }
        this.$message.error('语言获取失败' + res.data.msg)
      })
    },
    GetUser () {
      this.api.UserManageList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.userlist = res.data.data.data
          this.GetLanguage()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Getapp () {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          this.GetUser()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['ruleForm'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    submitForm () {
      let params = {}
      this.logsitem.forEach(item => {
        if (item.language_code) {
          params[item.language_code] = {
            title: item.title,
            abstract: item.abstract,
            content: item.content,
            preview_img: item.preview_img,
            push_img: item.push_img,
          }
        }
      })
      this.api.SendMessage({
        is_email: this.form.is_email,
        message: params,
        msg_type: this.form.msg_type,
        push_email_conf: this.form.is_email==1&&this.fillType==1?{
            host: this.form.push_email_conf.host,
            port: +this.form.push_email_conf.port,
            username: this.form.push_email_conf.username,
            password: this.form.push_email_conf.password,
            from_name: this.form.push_email_conf.from_name,
            ssl: this.form.push_email_conf.ssl,
        }:null,
        push_email_conf_code: this.form.is_email==1&&this.fillType==2?{
          code: this.form.push_email_conf_code.code
        }:null,
        schema: this.form.schema,
        uid: this.form.uid,
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('下发成功')
          return
        }
        this.$message.error('下发失败' + res.data.msg)
      })
    }
  },
  filters: {},
  mounted () { },
  created () {
    this.Getapp()
  }
}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
